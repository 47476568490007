import React from "react";
import logo from "../../styles/images/AOMR_Rose-Croix1-removebg-preview.png";

const Header = () => {
  return (
    <header id="main-header">
      <div id="logo">
        <img src={logo} alt="logo" />
      </div>
      <div>
        <p id="title" className={"header-white-theme"}>
          Auguste Ordre de la Rose Mystique
        </p>
      </div>
    </header>
  );
};

export default Header;
