import React from "react";

const Home = () => {
  return (
    <header id="home-header">
      <div id="home-header-title-container">
        <h1>Auguste Ordre de la Rose Mystique</h1>
        <p>Filiation directe de l’Hermetic Order of the Golden Dawn</p>
        <p>par l’Ordre Rosicrucien de l’Alpha Oméga</p>
      </div>
      <div id="home-header-button-container">
        <a href="#main-header">Entrer</a>
      </div>
    </header>
  );
};

export default Home;
