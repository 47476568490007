import React from "react";
import William_Wynn_Westcott from "../../styles/images/William_Wynn_Westcott.png";
import William_Woodman from "../../styles/images/William_Woodman.png";
import Samuel_Liddel_Mathers from "../../styles/images/samuel_mathers_picture.jpg";
import Eliphas_Levi from "../../styles/images/eliphas_levi_picture_2.png";

const Presentation = () => {
  return (
    <section id="section-presentation">
      {/* <div className={"h2-container presentation-titre-white-theme"}>
        <h2>Présentation</h2>
      </div> */}
      <article id="presentation-container">
        <span id="span-title">
          Extraits de "La Voie de l’Aurore" par HieroSolis
        </span>
        <h3>Introduction – Qu’est-ce que la Golden Dawn ?</h3>
        <p>
          La Golden Dawn n’est pas une religion. Elle n’est pas non plus un
          « coven de sorciers » ou une école de magie. Malgré ses liens avec la
          franc-maçonnerie, elle n’est pas un simple ordre paramaçonnique ou un
          club pour érudits nostalgiques de l’Antiquité.
        </p>
        <p>
          Elle est un ordre hermétique initiatique « secret », fondé en
          Angleterre à la fin du XIX e siècle, qui a été révélé au grand public
          par une série d’indiscrétions. Cet ordre a suscité énormément
          d’intérêt dans les cercles occultes de la société anglaise et
          écossaise, et plus tard, américaine et néo-zélandaise.
        </p>
        <p>
          Son influence sur le monde de l’occultisme dans son ensemble est
          indéniable.
        </p>
        <p>
          Pourquoi un tel succès ? Pourquoi une telle popularité ? Qu’y avait-il
          dans ce que j’appellerai la « première Golden Dawn », c’est-à-dire la
          Golden Dawn des débuts, celle de ses fondateurs, qui a tant fasciné,
          et qui fascine toujours tellement ?
        </p>
        <p>
          En France en particulier, on a une image très imparfaite de la Golden
          Dawn et de son histoire. C’est avant tout par manque d’information,
          même s’il est arrivé que la vérité soit déformée par désir de
          sensationnel ou par pure malveillance. Ses fondateurs étaient des
          hommes comme les autres, avec leurs aspirations et leur travers ; ceux
          et celles qu’ils ont initiés, et qui ont poursuivi leurs travaux,
          avaient eux aussi leurs qualités et leurs défauts, et s’ils ont pu
          commettre des erreurs, ils ont le plus souvent péché par idéalisme que
          par désir de faire le mal. Comme toujours, il ne faut pas « jeter le
          bébé avec l’eau du bain ».
        </p>
        <p>
          Mais en vérité, le véritable secret est incommunicable. Il ne peut
          s’obtenir que par une expérience directe, individuelle, absolue. Toute
          tentative pour l’exprimer, autrement que de façon symbolique, parlant
          à l’âme sans médiation, est vaine, vouée à l’échec. Car il ne peut
          être saisi ni par les mains, trop grossières ; ni par le cœur, trop
          inconstant ; ni par le cerveau, trop pointilleux.
        </p>
        <p>
          La Golden Dawn est une expérience qui doit se vivre pour être
          comprise ; elle ne peut être enfermée dans des écrits, même s’ils
          peuvent donner accès à ses mystères.
        </p>
        <h3>Les origines</h3>
        <p>
          L’arbre est dans la graine ; une pensée, un mouvement, une
          organisation, ne peuvent se comprendre que si l’on connaît les
          circonstances qui leur ont donné naissance. Un phénomène comme la
          Golden Dawn ne naît pas dans une splendide isolation, ne se manifeste
          pas dans le monde comme Athéna sortie armée et casquée du front de
          Zeus. La Golden Dawn est le produit de son époque, et elle en porte
          tous les stigmates.
        </p>
        <p>
          À bien des égards, la fin du dix-neuvième siècle est une époque
          exceptionnelle. Sur le plan scientifique, les Occidentaux en général,
          et les Britanniques en particulier, sont convaincus d’être sur le
          point de percer les derniers mystères de l’univers physique. Sur le
          plan religieux, les esprits sont apaisés. L’Angleterre de la fin du
          XIX e siècle pratique une forme de tolérance bienveillante ; si
          l’église officielle est anglicane, elle ne persécute plus ni les
          nombreuses dénominations protestantes, ni les catholiques, ni les
          juifs. La conquête de l’Inde a mis les classes instruites de la
          société en contact avec le bouddhisme et les diverses formes
          d’hindouisme, qui les intriguent et les fascinent ; la Société
          théosophique d’Helena Blavatsky, si imprégnée de philosophie
          orientale, naît à New York en 1875, soit une douzaine d’années avant
          la Golden Dawn.
        </p>
        <p>
          Les mystères anciens attirent tout autant les esprits tournés vers
          l’inconnu : les fouilles archéologiques d’Égypte et de Mésopotamie
          révèlent sans cesse de nouveaux secrets, on scrute les textes anciens
          (comme le Corpus Hermeticum ou les Oracles chaldaïques) pour y trouver
          des enseignements ésotériques…
        </p>
        <p>
          Les sociétés « occultes » ne manquent pas. Leur naissance est
          facilitée par les loges maçonniques, depuis longtemps bien installées
          dans toute l’Europe, qui constituent une véritable pépinière de
          mouvements et d’ordres divers.
        </p>
        <p>
          En leur sein, le consensus général s’accorde à dire qu’il existe une
          Tradition Primordiale, une Source commune aux traditions de tous les
          peuples, de toutes les cultures… y compris au judaïsme, et donc au
          christianisme. Une Tradition qui serait dépositaire d’une technique
          secrète connue des Anciens, ces monstres sacrés, mais oubliée de notre
          humanité tombée dans la matière. Une technique qui permettrait de se
          (re)mettre « en phase » avec l’univers et les dieux. Et cette idée
          exerce un attrait irrésistible sur un grand nombre d’individus.
        </p>
        <p>
          Mais où trouver cette Tradition primordiale ? Qui en est dépositaire ?
          Quelle est la grande tradition ésotérique du monde occidental
          moderne ? Celle qui fait rêver depuis des siècles et ne se fait
          connaître que rarement, qui détiendrait les plus grands secrets de
          l’alchimie et de la réintégration au Divin ?
        </p>
        <p>
          La Rose-Croix, bien évidemment. Au XIX e siècle, la Rose-Croix fait
          rêver plus encore qu’à notre époque, et son foyer est supposé se
          trouver en Allemagne ; et c’est d’Allemagne que les fondateurs de la
          Golden Dawn ont prétendu détenir leur filiation.
        </p>
        <p>
          Cette notion de « filiation » est fondamentale pour les ordres
          initiatiques. Créer quelque chose de nouveau, qui ne soit pas relié
          directement à une tradition ancienne et vénérable, est inconcevable ;
          ce qu’il faut, c’est de l’ancienneté, et plus on peut remonter loin,
          plus on est fiable et honorable. Pour que l’Ordre de la Golden Dawn
          puisse exister, et attirer des candidats à l’initiation, il fallait
          qu’il émane d’un Ordre ancien et vénérable, de préférence, bien sûr,
          de la Rose-Croix. Ainsi, pour se donner une légitimité, les fondateurs
          de la Golden Dawn ont dit avoir reçu d’une certaine « Fräulein
          Sprengel » une charte et des manuscrits leur permettant de fonder un
          temple d’un ordre issu de la Rose-Croix.
        </p>
        <h3>Les Fondateurs</h3>
        <p>
          La Golden Dawn tout entière est créé par trois hommes (tous maîtres
          maçons et membres de la SRIA) : William Wynn Westcott, William Robert
          Woodman, et Samuel Liddell Mathers. Ces trois fondateurs se sont
          réunis en 1887 pour mettre au point la Golden Dawn, à partir de
          données trouvées dans ce manuscrit.
        </p>
        <h4 className="text-italic">William Wynn Wescott</h4>
        <p>
          Le Dr William Wynn Westcott, médecin légiste londonien, franc-maçon et
          Secrétaire Général de la SRIA, était très actif dans la plupart des
          ordres ésotériques de l’époque en Angleterre. Il était ainsi membre
          également de la Société théosophique. C’était un homme aimable, très
          connu et très respecté. Il a publié de nombreuses œuvres, dont des
          traductions en anglais du
          <span className="text-italic"> Sépher Yétsirah</span>, du
          <span className="text-italic"> Rituel du Sanctum Regnum </span>
          de Lévi (1887), et de son
          <span className="text-italic"> Dogme et Rituel de Haute Magie </span>
          (1896), intitulé en anglais
          <span className="text-italic"> Transcendental Magic</span>.
        </p>
        <picture>
          <img
            src={William_Wynn_Westcott}
            alt="William Wynn Westcott"
            title="William Wynn Westcott"
          />
          <figcaption>
            <span>William Wynn Westcott</span>
          </figcaption>
        </picture>
        <p>
          Westcott est de loin le personnage le plus important de ce trio de
          fondateurs. C’est lui qui a découvert le manuscrit chiffré qui a
          permis la fondation de la Golden Dawn ; c’est sa vision qui a été à
          l’origine de la Golden Dawn ; et c’est également lui qui a inventé la
          soi-disant succession rosicrucienne de la Golden
        </p>
        <h4 className="text-italic">William Woodman</h4>
        <p>
          Le Dr Woodman était médecin à la retraite et horticulteur. Maçon
          admiré et bien connu, membre influent de la SRIA, c’était le coéditeur
          du journal de cette société, « The Rosicrucian ». Il est devenu
          Supreme Magus en 1878, et il a développé la SRIA dans toute
          l’Angleterre et en Australie et en Amérique. La SRIA est devenue grâce
          à lui la plus grande société rosicrucienne au monde. C’était un
          excellent kabbaliste, et il a contribué à développer le système
          kabbalistique de la Golden Dawn. Il devait aussi développer les
          enseignements de l’Ordre sur l’alchimie, mais son décès en 1891 l’en a
          empêché.
        </p>
        <picture>
          <img
            src={William_Woodman}
            alt="William Woodman"
            title="William Woodman"
            className="float-left"
          />
          <figcaption>
            <span className="text-italic">William Woodman</span>
          </figcaption>
        </picture>
        <h4 className="text-italic">Samuel Liddel Mathers</h4>
        <p>
          Ancien boxeur professionnel, Mathers était aussi maçon et membre du
          « High Council » de la SRIA ; il a publié un certain nombre d’articles
          dans le journal de cette Société. Très érudit, mais sans formation
          universitaire, il passait des journées entières à consulter des textes
          de magie au British Museum. Il a publié un certain nombre d’œuvres sur
          l’occultisme, et a traduit en anglais divers grimoires, comme
          <span className="text-italic">
            {" "}
            la Magie Sacrée d’Abramelin le Mage{" "}
          </span>
          (connu en France par l’édition qu’en a fait Robert Ambelain), les
          <span className="text-italic"> Clavicules de Salomon </span> (très
          revisitées) et le
          <span className="text-italic"> Grimoire de l’Armadel</span>. Il a
          aussi traduit la{" "}
          <span className="text-italic"> Kabbala Denudata </span> de Knorr von
          Rosenroth du latin en anglais, sous le titre
          <span className="text-italic"> The Kabbalah Unveiled </span> (La
          Kabbale dévoilée). Il est évident, à la lecture de ses œuvres, qu’il
          avait souvent trop d’imagination et qu’il faisait des raccourcis
          suspects. Ses contemporains s’accordent à le décrire comme
          charismatique et fascinant ; mais il avait aussi un tempérament
          tyrannique et violent. Il se voulait magicien, et c’était un
          ritualiste doué ; mais il a souvent vécu dans la pauvreté.
        </p>
        <picture>
          <img
            src={Samuel_Liddel_Mathers}
            alt="Samuel Liddel Mathers"
            title="Samuel Liddel Mathers"
          />
          <figcaption>
            <span>Samuel Liddel Mathers</span>
          </figcaption>
        </picture>
        <p>
          Après le décès de Woodman en 1891 et le départ plus ou moins forcé de
          Westcott en 1896 (sous la pression de sa hiérarchie), c’est Mathers
          qui est devenu le chef principal de l’Ordre, et qui a fait de la
          Golden Dawn ce qu’elle est devenue : un véritable ordre magique
          initiatique.
        </p>
        <hr />
        <p>
          La Golden Dawn, loin d’être sortie de nulle part, est l’aboutissement
          d’un processus ; elle est la concrétisation du rêve de nombreuses
          personnes, dont certaines ont pris une part plus active à sa mise au
          monde, mais qui avaient toutes un idéal commun, dont il sera question
          plus loin dans ce livre. Elle puise dans la pensée néoplatonicienne de
          l’Antiquité tardive, dans les manifestes rosicruciens et dans les
          ordres (réels ou fantasmés) qui ont tenté de les faire vivre au XVIII
          e siècle, et dans la pensée de nombreux auteurs.
        </p>
        <p>
          Parmi ces derniers, il y en a un qui a une position centrale :
          Alphonse-Louis Constant (1810-1875), plus connu sous son pseudonyme
          hébraïsé Éliphas Lévi Zahed. Son œuvre maîtresse,
          <span className="text-italic">
            {" "}
            Dogme et Rituel de la Haute Magie
          </span>
          , a été traduite en anglais par Westcott, et a eu une grande influence
          sur les prédécesseurs et les fondateurs de la Golden Dawn. On retrouve
          en effet dans les rituels de cet ordre certains éléments et quelques
          passages directement empruntés à ce livre.
        </p>
        <picture>
          <img src={Eliphas_Levi} alt="Eliphas Levi" title="Eliphas Levi" />
          <figcaption>
            <span>Eliphas Levi</span>
          </figcaption>
        </picture>
      </article>
    </section>
  );
};

export default Presentation;
