import React from "react";
import Fludd_summum_bonum from "../../styles/images/Fludd_summum_bonum.jpg";
// import Academie_de_Platon from "../../styles/images/Academie_de_Platon.jpg";

const Faq = () => {
  return (
    <section id="section-faq">
      <div className={"h2-container faq-titre-white-theme"}>
        <h2>Questions fréquentes</h2>
      </div>
      <article id="faq-container">
        <picture>
          <img
            src={Fludd_summum_bonum}
            alt="Robert Fludd - Summum Bonum"
            title="Robert Fludd - Summum Bonum"
          />
          <figcaption>
            <span>Robert Fludd - Summum Bonum</span>
          </figcaption>
        </picture>
        <h3>Qu’est-ce que la Golden Dawn ?</h3>
        <p>
          Ce que l’on appelle communément la Golden Dawn ou GD est à la fois un
          ordre hermétique initiatique et une école d’occultisme. Elle est
          d’inspiration rosicrucienne et maçonnique, et a été fondée en
          Angleterre en 1888 sous le nom de « Hermetic Order of the Golden Dawn
          in the Outer ». Sous ce nom, elle a cessé d’exister en 1905, mais ses
          membres ont immédiatement fondé deux organisations qui en sont les
          héritières directes, l’Alpha-Omega de Samuel Mathers et la Stella
          Matutina du Dr. Felkin. Ces organisations ont eu une histoire
          complexe. Aujourd’hui, un certain nombre d’ordres et de temples (c’est
          ainsi que l’on nomme les « loges » de la Golden Dawn) se réclament de
          la GD originelle, de l’AO ou de la Stella Matutina, soit qu’ils y
          soient liés par filiation directe, soit qu’ils soient des re-créations
          ou des résurgences de ces ordres.
        </p>
        <h3>À qui s’adresse la GD ?</h3>
        <p>
          Le courant véhiculé par la GD s’adresse à toutes les personnes
          majeures engagées dans une quête spirituelle sincère dans laquelle les
          traditions occultes du bassin méditerranéen et d’Europe occidentale
          jouent un grand rôle : hermétisme, néoplatonisme, astrologie, gnose,
          kabbale, alchimie, tarot, magie cérémonielle… La GD n’étant pas une
          religion, aucune appartenance religieuse n’est exigée ; il faut
          cependant dire qu’étant donnée l’orientation spirituelle de la GD, il
          est difficile de pratiquer ce qu’elle enseigne dans une perspective
          athée.
        </p>
        <h3>Comment entrer dans la GD ?</h3>
        <p>
          Il faut prendre contact avec un Temple. Chaque Temple a ses propres
          modalités. Une « lettre de motivation » est le plus souvent demandée,
          le postulant faisant état des raisons qui le poussent à vouloir se
          faire initier dans ce courant. La GD étant une structure initiatique,
          il est évident que toutes les demandes d’initiation ne peuvent pas
          être acceptées. Les critères de sélection ne dépendent ni du sexe, ni
          de la profession, ni des origines, ni de la religion.
        </p>
        <h3>Comment progresse-t-on dans la GD ?</h3>
        <p>
          À chaque degré, l’initié doit apprendre un certain nombre de données
          et de techniques, sur lesquelles il est ensuite évalué ; la réussite à
          l’examen lui donne accès à la cérémonie de passage au degré suivant, à
          discrétion des Chefs du Temple.
        </p>
        <h3>Comment un Temple est-il organisé ?</h3>
        <p>
          Chaque Temple est une structure gérée par trois Chefs formant un
          triumvirat d’égaux, assistés par un Collège d’Adeptes (initiés ayant
          atteint au moins le 5e degré). Les autres initiés du Temple peuvent
          être amenés à jouer un rôle actif comme « Officiers » lors des rituels
          qui ont lieu lors des tenues du Temple.
        </p>
      </article>
    </section>
  );
};

export default Faq;
