import React, { useState } from "react";

const Nav = () => {
  const [active, setActive] = useState(0);

  return (
    <nav>
      <ul className={"nav-white-theme"}>
        <li className={active === 0 ? "active" : null}>
          <a href="#section-presentation" onClick={() => setActive(0)}>
            Présentation
          </a>
        </li>
        {/* <li className={active === 1 ? "active" : null}>
          <a href="#section-histoire" onClick={() => setActive(1)}>
            Histoire
          </a>
        </li> */}
        {/* <li className={active === 2 ? "active" : null}>
          <a href="#section-filiations" onClick={() => setActive(2)}>
            Filiations
          </a>
        </li> */}
        {/* <li className={active === 3 ? "active" : null}>
          <a href="#section-cursus" onClick={() => setActive(3)}>
            Cursus
          </a>
        </li> */}
        {/* <li className={active === 4 ? "active" : null}>
          <a href="#section-article" onClick={() => setActive(4)}>
            Article
          </a>
        </li> */}
        <li className={active === 1 ? "active" : null}>
          <a href="#section-faq" id="faq-desktop" onClick={() => setActive(1)}>
            Questions fréquentes
          </a>
          <a href="#section-faq" id="faq-mobile" onClick={() => setActive(1)}>
            FAQ
          </a>
        </li>
        <li className={active === 2 ? "active" : null}>
          <a href="#contact" onClick={() => setActive(2)}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
