import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../features/form/formSlice";

const Footer = () => {
  const form = useSelector((state) => state.form.value);
  const dispatch = useDispatch();

  const goToContactForm = () => {
    dispatch(toggleForm(!form));
  };

  return (
    <footer id="footer">
      <div>
        <p onClick={() => goToContactForm()}>
          Auguste Ordre de la Rose Mystique © copyright 2023
        </p>
      </div>
    </footer>
  );
};

export default Footer;
