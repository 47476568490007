import React from "react";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import Presentation from "../Presentation/Presentation";
// import Histoire from "../Histoire/Histoire";
// import Filiations from "../Filiations/Filiations";
// import Filiations_2 from "../Filiations/Filiations_2";
// import Cursus from "../Cursus/Cursus";
// import Article from "../Article/Article";
import Faq from "../Faq/Faq";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";

const Main = () => {
  return (
    <main id="main" className={"white-theme"}>
      <Header />
      <Nav />
      <Presentation />
      {/* <Histoire /> */}
      {/* <Filiations /> */}
      {/* <Filiations_2 /> */}
      {/* <Cursus /> */}
      {/* <Article /> */}
      <Faq />
      <Contact />
      <Footer />
    </main>
  );
};

export default Main;
