import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import formReducer from '../features/form/formSlice';
import whiteThemeReducer from '../features/whiteTheme/whiteThemeSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    form: formReducer,
    whiteTheme: whiteThemeReducer,
  },
});
