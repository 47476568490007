import { createSlice } from '@reduxjs/toolkit';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    value: false,
  },
  reducers: {
    toggleForm: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleForm } = formSlice.actions;

export default formSlice.reducer;
