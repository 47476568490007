import React from "react";
// import React, { useState } from "react";

const Contact = () => {
  // const [displayForm, setDisplayForm] = useState(false);
  // const [displayMessage, setDisplayMessage] = useState("");

  // const activeForm = () => {
  //   setDisplayForm(!displayForm);
  // };

  // const sendMessage = (e) => {
  //   e.preventDefault();
  //   setDisplayMessage("Message envoyé");
  //   setTimeout(() => setDisplayMessage(""), 3000);
  //   setTimeout(() => setDisplayForm(!displayForm), 3000);
  // };

  return (
    <section id="contact">
      {/* {displayForm && (
        <form action="" id="contactForm">
          <p>{displayMessage}</p>
          <label htmlFor="message">Nom</label>
          <input type="text" />
          <label htmlFor="message">Prénom</label>
          <input type="text" />
          <label htmlFor="message">Email</label>
          <input type="email" />
          <label htmlFor="message">Objet</label>
          <input type="text" />
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="8" cols="33"></textarea>
          <button type="submit" form="contactForm" onClick={sendMessage}>
            Envoyer
          </button>
        </form>
      )} */}
      {/* <div id="contact-button-container">
        <button onClick={activeForm}>Contact</button>
      </div> */}
      <article>
        <p>contact@aorm-aomr.com</p>
      </article>
    </section>
  );
};

export default Contact;
