import { createSlice } from '@reduxjs/toolkit';

export const whiteThemeSlice = createSlice({
  name: 'whiteTheme',
  initialState: {
    value: false,
  },
  reducers: {
    toggleTheme: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleTheme } = whiteThemeSlice.actions;

export default whiteThemeSlice.reducer;
